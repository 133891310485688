import Blocks, { BlockType } from 'library/blocks'

import type { GetStaticPropsResult } from 'next'
import Head from 'next/head'
import { HomePageData } from 'library/types/strapi/objects'
import Layout from 'library/layout/Layout'
import React from 'react'
import getHomePageData from 'library/content/getHomePageData'

type HomePageProps = {
  data: HomePageData
}

const HomePage = ({ data }: HomePageProps) => {
  const blocks = data.attributes.blocks
  const highestZIndex = blocks.length

  return (
    <>
      <Head>
        <title>Onetrace | Fire Stopping App</title>
      </Head>

      <Layout>
        {data.attributes.blocks.map((block, blockIndex) => {
          const key = block.__component.replace('blocks.', '')
          const BlockComponent: any = Blocks[key as BlockType]
          const zIndex = highestZIndex - blockIndex
          const blockData = { ...block, zIndex }

          return <BlockComponent key={blockIndex} {...blockData} />
        })}
      </Layout>
    </>
  )
}

export default HomePage

export async function getStaticProps(): Promise<GetStaticPropsResult<HomePageProps>> {
  const data = await getHomePageData()

  return {
    props: {
      data,
    },
    revalidate: 1,
  }
}
